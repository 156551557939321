<template>
	<div class="whiteBack" :class="{ invalid: !currentUser.emailVerified }">

  	<div class="flex align-center justify-space-between">
  		<label for="email">Email</label>
  		<span v-if="currentUser && currentUser.emailVerified" class="caption mb-2">Verified <i style="color: green" class="fas fa-check"></i></span>
  		<div v-if="!currentUser || !currentUser.emailVerified">
  			<span class="caption mr-3">not verified</span>
  			<button class="btn btn__small btn__outlined btn__flat mb-2" @click="resendVerification()">
          resend link
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest1">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>
  		</div>
  	</div>
    <input type="email" autocomplete="email" placeholder="" v-model.trim="userProfile.email" id="email" name="email" @change="updateEmail()" required/>


	</div>
</template>

<script>
const fb = require('../../firebaseConfig.js')
import { mapState } from 'vuex'

export default {
  props: ['userProfile'],
  data: () => ({
    performingRequest1: false,
  }),
  
  computed: {
     ...mapState([ 'currentUser']),
  },
  methods: {
    updateEmail() {
    	let userProfile = this.userProfile
    	this.$store.dispatch('updateUserEmail', userProfile)
      this.$store.dispatch('getUserProfile')
      this.resetSubmission = true
    },
    resendVerification() {
      this.performingRequest1 = true
    	this.$store.dispatch('resendVerificationEmail')
      setTimeout(() => {
          this.performingRequest1  = false
      }, 2000)
      this.resetSubmission = true
    },
   
   
  }
}
</script>