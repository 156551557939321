<template>
  <div class="dashboard">
    <div class="dashboard__container mb-5">
      <div class="dashboard__container--header eventsHeader pb-3 pt-0 pr-0 pl-0">

        <h1 v-if="isShift">Shift Details</h1>
        <h1 v-if="isMyShifts && !isShift">My Schedule</h1>
        <h1 v-if="!isMyShifts && !isShift">Upcoming Gigs</h1>
        <span class="flex align-center mt-3">
          <!-- <button class="btn btn__outlined mr-3" @click="deleteGroup()" :disabled="!clean" v-if="isOwner || isKaela">delete group</button> -->
          <span v-if="!isShift">
            <!-- <router-link :to="{name: 'myGroups'}">
              <button class="btn mb-3" v-bind:class="{ 'btn__dark': isMyGroups, 'btn__outlined': !isMyGroups }">My Groups</button>
            </router-link>
            <router-link :to="{name: 'newGroups'}">
              <button class="btn ml-3 mb-3" v-bind:class="{ 'btn__dark': isNewGroups, 'btn__outlined': !isNewGroups }">Join Groups</button>
            </router-link> -->
            <!-- 
            <router-link :to="{name: 'availableShifts'}">
              <button class="btn ml-4 mb-5 mt-2" v-bind:class="{ 'btn__dark': isShifts, 'btn__outlined': !isShifts }">Available Shifts</button>
            </router-link>
            <router-link :to="{name: 'myShifts'}">
              <button class="btn ml-4 mb-5 mt-2" v-bind:class="{ 'btn__dark': isMyShifts, 'btn__outlined': !isMyShifts }">My Schedule</button>
            </router-link> -->
          </span>
          <span v-if="isShift">
            <div>
              <button class="btn btn__outlined btn__small mb-4 mt-2" @click="goBack">Go Back<i class="fas fa-arrow-left fa-2x ml-3"></i></button>
            </div>
          </span>
          <!-- <router-link :to="{name: 'myShift'}">
            <button class="btn ml-3 mb-3" v-bind:class="{ 'btn__dark': isShift, 'btn__outlined': !isShift }">Shift</button>
          </router-link> -->

        </span>
      </div>

      <!-- <ProfileDOB :userProfile="userProfile" v-if="userProfile && userProfile.dob && !userProfile.is18" />

      <ProfileEmail :userProfile="userProfile" v-if="currentUser && !currentUser.emailVerified" />

      <ProfilePhoneVerification v-if="userProfile && userProfile.phoneVerified != 'approved'" /> -->
      
      <!-- <router-view v-if="isApproved" :key="$route.params.id" /> -->
      <router-view :key="$route.params.id" />
      <!-- <router-view :key="$route.params.id" /> -->
<!--       <div class="dashboard__container--body pt-3 eventCardContainer">
        <div v-for="(item, index) in groups" :key="item.id" @click="onItemClick(item)">
          
            {{item.title}}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import ProfilePhoneVerification from '@/components/Profile/ProfilePhoneVerification.vue'
import ProfileEmail from '@/components/Profile/ProfileEmail.vue'
import ProfileDOB from '@/components/Profile/ProfileDOB.vue'

export default {
  name: 'findWork',
  data: () => ({
    performingRequest: false,
  }),
  components: {
    ProfilePhoneVerification,
    ProfileEmail,
    ProfileDOB
  },
  computed: {
    ...mapState(['userProfile', 'currentUser']),
    isApproved() {
      return ((this.userProfile && this.userProfile.phoneVerified == 'approved' && this.userProfile.is18) && (this.currentUser && this.currentUser.emailVerified))
    },
    // isMyGroups() {
    //   return this.$route.name == 'myGroups';
    // },
    // isNewGroups() {
    //   return this.$route.name == 'newGroups';
    // },
    isShifts() {
      return this.$route.name == 'availableShifts';
    },
    isMyShifts() {
      return this.$route.name == 'myShifts';
    },
    isShift() {
      return (this.$route.name == 'myShift' || this.$route.name == 'shiftView');
    },
  },
  // created () {
  //   this.$store.dispatch("getAvailableShifts")
  //   this.$store.dispatch("getMyShifts")
  // },
  methods: {
     goBack() {
      router.go(-1)
    },
  },
  // beforeDestroy () {
  //   this.$store.dispatch('clearShiftLists')
  // }
}
</script>