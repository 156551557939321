<template>
	<div class="whiteBack" :class="{ invalid: !userProfile.is18 }">
    <div>
      <div class="flex align-center justify-space-between">
        <label for="dob">Date of Birth</label>
        <span v-if="userProfile.is18" class="caption mb-2">Verified <i style="color: green" class="fas fa-check"></i></span>
        <span v-if="!userProfile.is18" class="caption mb-2">not 16 <i style="color: red" class="fas fa-close"></i></span>
      </div>
      <div>
      <input type="date" placeholder="" v-model.trim="userProfile.dob" id="dob" name="dob" inputmode='none' @blur="checkDOB(userProfile.dob)" required/>
      </div>
      <div class="mb-2" v-if="!userProfile.is18">
        <span class="caption mt-2" style="color:red !important;">You must be 16 to work for JUMP</span>
      </div>
    </div>
	</div>
</template>

<script>
const fb = require('../../firebaseConfig.js')
import { mapState } from 'vuex'

export default {
  props: ['userProfile'],
  mounted() {
    this.checkDOB(this.userProfile.dob)
  },
  computed: {
     ...mapState([ 'currentUser']),
  },
  methods: {
    checkDOB(dob) {
      let userProfile = this.userProfile
      console.log(dob)
      var min_age = 16

      const d = new Date(dob);
      console.log(d)
      let year = d.getUTCFullYear();
      console.log(year)
      var month = d.getUTCMonth()
      console.log(month)
      var day = d.getUTCDate()
      console.log(day)

      console.log(year + min_age)

      var theirDate = new Date((year + 16), month, day)
      console.log(theirDate)
      var today = new Date
      console.log(today)
      if ( (today.getTime() - theirDate.getTime()) < 0) {
        alert("You must be 16 years old to work for JUMP");
        let dob = this.userProfile.dob
        this.userProfile.is18 = false
        this.$store.dispatch('updateUser', userProfile)
       }
       else {
        let dob = this.userProfile.dob
        this.userProfile.is18 = true
        this.$store.dispatch('updateUser', userProfile)
       }
    },
  }
}
</script>